// ==========================================================================
// #FOOTER
// ==========================================================================
#footer {
  background-color: #4A4A4A;
  color: white;

  .heading {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .footer__logo {
    padding: 20px;
    text-align: center;
  }

  .footer__social {
    padding: 20px;

    li {
      margin-right: 15px;
    }
  }

  .footer__inner {
    padding-top: 40px;

    a {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      transition: all .3s;

      &:hover {
        border-color: palette(brand, yellow);
      }
    }
  }

  ul {
    margin: 0;
    list-style: none;
    padding-top: 0;

    li {
      padding-bottom: 5px;
    }
  }

  p, a {
    color: white;
    font-size: 1.6rem;
  }

  .footer__copy {
    padding-top: 20px;
  }
}

@include breakpoint(small) {

  #footer {

    .footer__top {
      display: flex;
    }

    .footer__social, .footer__logo {
      flex: 1;
    }

    .footer__social {
      position: relative;
      padding: 20px 0;
      background-color: white;

      li {
        margin-right: 15px;
      }

      &::before {
        content:"";
        position: absolute;
        top:0;
        bottom:0;
        right:100%;
        width: 100vw;
        background-color: white;
      }

      &::after {
        content:"";
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 85px 85px;
        border-color: transparent transparent palette(brand,grey) transparent;
      }
    }

    .footer__logo {
      text-align: right;
      padding: 20px 0 0 0;
    }

    .footer__inner {
      padding-top: 70px;
    }


    .footer__copy {
      padding-top: 50px;
    }
  }
}

@include breakpoint(large) {
}