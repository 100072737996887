//=====================================
// #HELPERS
//=====================================
.img--responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.img--stretch {
  width: 100%;
}

.img--slight-border {
  border: solid #F9BE53 2px;
  font-size: 0;
}

.img--clip-left-top {
  position: relative;

  @include breakpoint-max(small) {
    max-width: 300px;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 70px 70px 0 0;
    border-color: white transparent transparent  transparent;

    @include breakpoint(medium) {
      border-width: 90px 90px 0 0;
    }
  }
}

.frame {
  width: 100%;
  height: 250px;
  @include breakpoint(small) {
    height: 395px;
  }
  @include breakpoint(medium) {
    height: 520px;
  }
  @include breakpoint(large) {
    height: 425px;
  }
}

@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return round($number * $n) / $n;
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

@function decimal-ceil($number, $digits: 0) {
  @return decimal-round($number, $digits, ceil);
}

@function decimal-floor($number, $digits: 0) {
  @return decimal-round($number, $digits, floor);
}

@function rem($size, $base: $max-font) {
  $remSize: decimal-ceil(($size / $base), 5);
  @return #{$remSize}rem;
}

@function gutter($lines: 1, $gutter: $base-line-height) {
  @if length($lines) == 1 {
    @return rem(($gutter * $lines) * 1px);
  } @else if length($lines) < 5 {
    $string: '';
    @for $i from 1 through length($lines) {
      $string: $string + ' ' + rem(($gutter * nth($lines, $i)) * 1px);
    }
    @return unquote($string);
  } @else {
    @warn 'To many values used in gutter function';
    @return null;
  }
}

@mixin trim() {
  > *:only-of-type:last-child,
  > *:last-child {
    margin-bottom: 0;
  }
}
