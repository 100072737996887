// ==========================================================================
// #ICONS
// ==========================================================================
.icon {
  @include bg-cover();
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  > svg { display: block; }
  path, polygon { transition: fill .3s; }
}

// ==========================================================================
// #ICONS-COLORS
// ==========================================================================
.icon--white {
  path { fill: white; }
}

.icon--black {
  path, polygon { fill: black !important; }
}

// Colors
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      path, polygon, rect, circle {
        fill: palette($color, $shade);
      }
    }
  }
}

// ==========================================================================
// #ICONS-SIZE
// ==========================================================================

.icon--10 {
  &,
  & > svg {
    width: 10px;
    height: 10px;
  }
}

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--50 {
  &,
  & > svg {
    width: 50px;
    height: 50px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

.icon--logo {
  &,
  & > svg {
    width: 130px;
    height: 36px;

    @include breakpoint(small) {
      width: 202px;
      height: 56px;
    }
  }
}

.icon--logo-footer {
  &,
  & > svg {
    width: 202px;
    height: 56px;
  }
}


.icon--quote {
  &,
  & > svg {
    width: 140px;
    height: 76px;
  }
}


// ==========================================================================
// #ICONS-SOCIAL
// ==========================================================================
.icon--facebook, .icon--twitter, .icon--instagram, .icon--mail {
  svg > * { fill: palette(black) }
}

a:hover {
  .icon--facebook svg > * { fill: #3b5998; }
  .icon--twitter svg > * { fill: #00b6f1; }
  .icon--instagram svg > * { fill: #c32aa3 }
  .icon--mail svg > * { fill: black; }
}

.social {
  position: relative;

  span {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(130deg);
    width: 0;
    height: 1px;
    background-color: #33003C;
    opacity: 0;
    transition: width .3s, opacity .3s;
  }

  &:hover {
    &::before {
      opacity: 1;
      width: 80px;
    }
  }
}