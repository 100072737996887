//=====================================
// #FORM
//=====================================
input {
  border: solid 1px black;
  border-radius: 0px;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:placeholder-shown) {
    background-color: #fbfbfb;
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

.input--hold {
  margin-bottom: 20px;
}

textarea {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

select {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px 40px 15px 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}


input[type=file] {
  position: relative;
  background-color: transparent;
  cursor: pointer;
  padding: 14px 0;
  width: 100%;
  color: palette(black);
  border: 0;
  border-bottom: 2px solid black;
  margin-bottom: 5px;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: 'Select a file';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: lighten(palette(black), 55%);

    white-space: nowrap;
    font-size: 1.6rem;
    pointer-events: none;
  }

  &:after {
    content: 'Choose file';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 1.4rem;
    background: #F5A623;
    color: white;
    padding: 10px 16px;
  }
}

//=====================================
// #FORM-TYPES
//=====================================
.dropdown {
  position: relative;
  display: inline-block;
  width: 350px;

  .trigger {
    cursor: pointer;
    position: relative;
    padding: 18px 40px 15px 0;
    font-size: 1.8rem;
    border-bottom: 1px solid #4A4A49;

    .arrow {
      pointer-events: none;
      position: absolute;
      right: 6px;
      top: 9px;
      transition: transform .3s;
    }
  }

  .sub-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(211, 211, 211, 0.50);
    transition: opacity .3s, visibility .3s;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li:last-of-type {
        padding-bottom: 0;
      }
    }

    a {
      display: block;
      transition: transform .3s;

      &:hover {
        transform: translateX(15px);
      }
    }
  }

  &.open {
    .sub-menu {
      opacity: 1;
      visibility: visible;
    }

    .trigger {
      .arrow {
        transform: scaleY(-1);
      }
    }
  }
}


.file-label {
  font-weight: 400;
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
}

.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;

  label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px;

    span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e;
    }
  }

  input, textarea, select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333;
  }

  input {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }

    &[type=file] {
      border: 0;
      padding-top: 0;
      width: 100%;
      display: flex;
    }
  }

  textarea {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      font-size: 14px;
    }
  }

  select {
    width: 100%;
    font-size: 18px;
    transition: border .5s;
  }

  &.active--input select {
    border-bottom: 2px solid white;

    ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}

#firstName {
  display: none;
}

// Honey pot

.checkbox {
  display: flex;

  label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.3;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 1px solid #e9dcd4;
      left: 0;
      background-color: #e9dcd4;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px;
    }

    &::after {
      color: white;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  input[type="checkbox"] {

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {
        background-color: palette(brand, yellow);
        border-color: palette(brand, yellow);
      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after {
        opacity: .5
      }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
      //transform: scale(1.2);
    }
  }
}


.form-hold {
  position: relative;

  .form-container {
    transition: opacity .3s;
  }

  .form-complete {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition-delay: .5s;
  }

  &--complete {

    .form-container {
      opacity: 0;
      visibility: hidden;
    }

    .form-complete {
      opacity: 1;
      visibility: visible;
    }
  }
}

.input--large-search {

  form, .form {
    width: 100%;
    display: flex;
  }

  input {
    color: white;
    background-color: transparent;
    font-size: 3rem;
    font-family: $fancy-heading-font;
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    line-height: 1;
    padding: 0 15px 10px 0;
    width: calc(100% - 70px);



    &::placeholder {
      color: white;
      opacity: 1;
    }

    &:focus {
      border-color: white;
    }

    &.active--input {

      border-bottom: 1px solid palette(brand, yellow);

      + button {
        opacity: 1;
      }
    }

    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: white;
    }

    &:valid ~ button {
      background-color: palette(brand, yellow);
    }
  }

  button {
    cursor: pointer;
    background-color: transparent;
    float: right;
    border: none;
    outline: none;
    font-size: 1.8rem;
    font-family: $fancy-heading-font;
    border-radius: 0;
    padding: 10px;
    width: 70px;
    opacity: 0;
    transition: all .3s;

    svg path {
      fill: white;
    }

    .icon {
      &,
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-page {
    border-color: #333;

    input {
      color: black;
      max-width: 600px;
      border-top: 1px solid #4A4A4A;
      border-left: 1px solid #4A4A4A;
      border-bottom: 1px solid #4A4A4A;
      padding: 15px;

      &::placeholder {
        color: #333;
        opacity: 1;
      }

      &.active--input {
        border-color: palette(brand, yellow);
      }

      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: #333;
      }
    }

    button {
      opacity: 1;
      background-color: #4A4A4A;
    }

    button svg path {
      fill: white;
    }
  }
}

.search-hold {
  p {
    margin-bottom: 7px;
  }
}

.form--hold {
  display: flex;
  margin-bottom: 10px;

  .email, .button {
    padding: 0 0 10px;
    border: none;
    border-bottom: 1px solid white;
    background-color: transparent;
  }

  .email {
    flex: 1;
    color: white;
  }

  .button {
    color: #ffad25;
    font-weight: 700;
  }
}


//=====================================
// #FORM-COLORS
//=====================================
.input--success {
  border-color: palette(green, success);
}

.input--error {
  border-color: palette(red, error);
}

