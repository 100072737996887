//=====================================
// #TILE
//=====================================
.tile--tech {
  display: block;
  margin-bottom: 40px;

  .inner {
    padding: 20px 0;
  }

  .p {
    font-weight: bold;
  }

  .link {
    display: flex;
    align-items: center;

    .p {
      margin-bottom: 0;
      padding-right: 10px;
    }

    .line {
      opacity: .5;
      position: relative;
      padding-left: 100px;
      display: inline-block;
      transition: padding .3s, opacity .3s;

      .icon {
        transition: transform .3s;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        background-color: palette(brand, yellow);
      }
    }
  }

  &:hover, &:focus {

    .line {
      opacity: 1;
      padding-left: 120px;

      .icon {
        transform: scale(1.2);
      }
    }
  }
}

.quote__block {

  .quote {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 1px;
      background-color: palette(brand, yellow);
    }

    > p {
      font-family: $fancy-heading-font;
      font-weight: 300;
      font-size: 2.5rem;
    }
  }
}

.tile--news, .tile--study {
  position: relative;
  min-height: 440px;
  margin-bottom: 35px;
  display: block;
  background-color: black;
  color: white;

  .bkg {
    @include bg-cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .55;
      transition: opacity .3s;
    }
  }

  .inner {
    position: relative;
  }

  .arrow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding: 30px;

    .icon {
      transition: transform .3s;
    }

    &::before {
      content: "";
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: 0;
      background-color: palette(brand, yellow);
      transition: width .3s;
    }
  }

  &:hover, &:focus {
    .arrow {
      .icon {
        transform: translateX(15px);
      }

      &::before {
        width: calc(100% - 15px);
      }
    }

    .bkg::before {
      opacity: .9;
    }
  }
}

.tile--news {
  padding: 30px;
  h3 {
    font-size: 2.2rem;
    display: inline-block;
    padding-bottom: 10px;
    border-bottom: 1px solid palette(brand, yellow);
  }
}

.tile--study-text {
  font-weight: 400;

  .h4 {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.tile--product-item {
  position: relative;
  min-height: 270px;
  margin-bottom: 35px;
  display: block;
  background-color: black;
  color: white;

  .bkg {
    @include bg-cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .55;
      transition: opacity .3s;
    }
  }

  .inner {
    position: relative;
  }

  .inner {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 10px 30px;
  }

  h3 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 2.4rem;
    display: inline-block;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 1px;
      background-color: palette(brand, yellow);
      transition: width .3s;
    }
  }

  &:hover {
    .bkg::before {
      opacity: .85;
    }
  }

  @include breakpoint(medium) {
    min-height: 285px;
  }

}

.tile--study {

  .inner {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 10px 30px;
  }

  h3 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 2.2rem;
    display: inline-block;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 1px;
      background-color: palette(brand, yellow);
      transition: width .3s;
    }
  }

  &:hover {
    h3::before {
      width: 100%;
    }
  }

  &-slim {
    min-height: 240px;
    max-width: 345px;
  }
}

.tile--product {
  position: relative;
  min-height: 440px;
  margin-bottom: 35px;
  display: block;
  background-color: black;
  color: white;

  .bkg {
    @include bg-cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .55;
      transition: opacity .3s;
    }
  }

  .inner {
    position: absolute;
    width: 100%;
    text-align: center;
    top:50%;
    transform: translateY(-50%);
  }

  h3 {
    width: 100%;
    position: relative;
    font-size: 2.8rem;
    display: inline-block;
    padding: 15px 30px;


    &::before {
      content:"";
      position: absolute;
      bottom:0;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 1px;
      background-color: palette(brand, yellow);
      transition: width .3s;
    }
  }

  &:hover, &:focus {
    .bkg::before {
      opacity: .9;
    }

    h3::before {
      width: 100%;
    }
  }
}

.team {
  margin-bottom: 20px;

  .team__name {
    font-family: $fancy-heading-font;
    font-weight: 300;
    font-size: 2.2rem;
  }
}

.tile--partner {
  img {
    padding: 20px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

@include breakpoint(medium) {

  .quote__block {
    .quote {
      &::before {
        width: 120px;
      }

      > p {
        font-size: 3rem;
      }
    }
  }
}