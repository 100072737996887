//=====================================
// #HEADINGS
//=====================================
$h1-size: 6.4rem;
$h2-size: 4.8rem;
$h3-size: 2.8rem;
$h4-size: 2.4rem;

$p-size: 1.8rem;
$p-size--small: 1.6rem;
$p-size--big: 2.2rem;

$heading-font: proxima-nova, sans-serif;
$fancy-heading-font: big-caslon-fb, serif;
$body-font: proxima-nova, sans-serif;

