.carousel-cell {
  @include breakpoint(400px) {
    max-height: 250px;
  }

  @include breakpoint(700px) {
    max-height:  438px;
  }

  @include breakpoint(1200px) {
    max-height:  750px;
  }
}

