.navigation-burger {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 5px 0;
  float: right;
}


.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 22px;
  margin: 0;
  position: relative;
  width: 35px;
  outline: none;

  span {
    height: 0;
    border-bottom: 2px solid palette(brand, yellow);
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(0);
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden;
  }

  &:hover {
    span {
      width: 100%;
      transform: translateY(-50%) translateX(0);
    }
  }

  &:after, &:before {
    transform: translateY(-50%);
    position: absolute;
    border-bottom: 2px solid palette(brand, yellow);;
    content: '';
    height: 0;
    width: 100%;
    right: 0;
    transition: all .3s ease-out;
  }

  &:before {
    top: 0;
  }

  &:after {
    top: 100%;
  }


  &.open {
    span {
      width: 100%;
      transform: translateY(-50%) rotate(45deg) translateX(0);
    }

    &:before {
      top: 50%;
      transform: translateY(-50%) rotate(135deg) translateX(0);
    }

    &:after {
      top: 50%;
      transform: translateY(-50%) rotate(225deg) translateX(0);
    }
  }
}

.header--flip {
  .hamburger {

    span, &:before, &:after {
      border-color: palette(brand, yellow);
    }
  }
}

@include breakpoint(small) {
  .hamburger {

  }
}

