//=====================================
// #BUTTON
//=====================================
.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  font-family: $body-font;
  font-weight: 600;
  color: #33003C;
  outline: none;
  background-color: transparent;
  border: 2px solid #33003C;
  padding: 15px 45px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;

  &:hover, &:focus {
    color: #fff;
    background-color: #33003C;
    svg path { fill: white; }
  }

  position: relative;

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(130deg);
    width: 0;
    height: 1px;
    background-color: palette(brand, yellow);
    opacity: 0;
    transition: width .3s, opacity .3s;
  }

  &:hover {
    &::before {
      opacity: 1;
      width: 120px;
    }
  }
}

.btn--full { width: 100%; }

.btn--r { float: right; }



//=====================================
// #BUTTON-COLORS
//=====================================
.btn--white {
  border-color: white;
  color: white;
}

.btn--white-fill {
  border-color: white;
  background-color: white;
  color: black;
}

.btn--simple {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  font-family: $body-font;
  font-weight: 600;
  color: white;
  outline: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 15px 45px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;

  &:hover, &:focus {
    color: #33003C;
    background-color: white;
    border-color: white;

    svg path {
      fill: white;
    }
  }
}

//=====================================
// #BUTTON-SIZES
//=====================================
.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}