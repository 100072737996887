//=====================================
// #COOKIE
//=====================================
#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: #F1EEED;

  p { color: #333; }
  .wrapper { padding: gutter(2) gutter(1); }
}

@include breakpoint(medium) {
  #cookie-message {
    .wrapper { padding: 40px; }
  }
}