//=====================================
// #PAGINATION
//=====================================
.pagination {
  text-align: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: palette(brand, yellow);
  }

  a, span {
    font-weight: 700;
    position: relative;
    display: inline-block;
    height: 30px;
    width: 30px;
    padding-top: 2px;
    margin: 0 8px;
    color: black;
    background-color: white;
    border-radius: 50%;

    @include breakpoint(medium) {
      margin: 0 12px;
    }
    @include breakpoint(large) {
      padding-top: 0;
    }

    &.current {
      background-color: palette(brand, yellow);
      color: white;
    }
  }

  .icon {
    padding: 4px;
    opacity: .5;
    margin: 0;
    transition: opacity .5s;

    &:hover {
      opacity: 1;
    }
  }

  .previous {
    margin-right: 10px;
  }

  .next {
    margin-left: 10px;
  }

  @include breakpoint(medium) {
    .previous {
      margin-right: 20px;
    }
    .next {
      margin-left: 20px;
    }
  }

  span {

  }
}