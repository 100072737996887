//=====================================
// #CTA
//=====================================
.cta {


  .cta__links {
    a {
      white-space: nowrap;
      border-bottom: 1px solid transparent;
      transition: border .3s;

      &:first-of-type {
        margin-right: 30px;
      }

      &:hover, &:focus {
        border-color: palette(brand, yellow);
      }
    }
  }
}

@include breakpoint(medium) {

}