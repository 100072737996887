/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * { width: 100%; }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

/*
  Footer grid modification
*/
.g--footer {
}

.g--gutter {
  .g__1-2 { lost-column: 1/2 2 gutter(1); }
  .g__1-3 { lost-column: 1/3 3 gutter(1); }
  .g__2-3 { lost-column: 2/3 3 gutter(1); }
}

.g__c {
  justify-content: center;
}

.g__ac {
  align-items: center;
}

@include breakpoint(small) {
  .gs__ac {
    align-items: center;
  }

  .gs__m1-2 {
    lost-move: 1/2 2 gutter(5);
  }

  .gs__m-1-2 {
    lost-move: -1/2 2 gutter(5);
  }

  .gs__1-2 {
    lost-column: 1/2;
  }

  .g--gutter {
    .gs__1-2 { lost-column: 1/2 2 gutter(5); }
    .gs__1-3 { lost-column: 1/3 3 gutter(5); }
    .gs__2-3 { lost-column: 2/3 3 gutter(5); }
  }

  .g--gutter-lg {
    .gs__1-2 { lost-column: 1/2 2 gutter(12); }
  }


}

@include breakpoint(medium) {

  .gm__ac { align-items: center; }

  .gm__1-3 {
    lost-column: 1/3;
  }

  .gm__1-2 {
    lost-column: 1/2;
  }

  .gm__1-4 {
    lost-column: 1/4;
  }

  .gm__31-100 {
    lost-column: 31/100;
  }
  .gm__23-100 {
    lost-column: 23/100;
  }


  .g--gutter {
    .gm__1-4 {
      lost-column: 1/4 4 gutter(5);
    }

    .gm__1-6 {
      lost-column: 1/6 6 gutter(5);
    }
  }

  .g--gutter-lg {
    .gm__1-2 {
      lost-column: 1/2 2 gutter(17);
    }
  }

}

@include breakpoint(large) {

  .g--gutter {
    .gl__1-3 { lost-column: 1/3 3 gutter(4); }
  }

  .gl__1-3 { lost-column: 1/3 0 0 flex; }



  .gl__1-4 {
    lost-column: 1/4;
  }
}
