@charset "UTF-8";
body.debug:after {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-transform: uppercase;
  padding: 17px 25px;
  position: fixed;
  font-size: 15px;
  font-weight: 700;
  bottom: 45px;
  right: 0;
  z-index: 999; }
  @media (max-width: 767px) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: x-small) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: 768px) {
    body.debug:after {
      content: "small"; } }
  @media (min-width: 992px) {
    body.debug:after {
      content: "medium"; } }
  @media (min-width: 1260px) {
    body.debug:after {
      content: "large"; } }

.tt-u {
  text-transform: uppercase; }

.bold {
  font-weight: 600; }

.normal {
  font-weight: 400; }

em, .italic {
  font-style: italic; }

.under {
  text-decoration: underline; }

.ta-c {
  text-align: center; }
  .ta-c > * {
    text-align: center; }

.ta-l {
  text-align: left; }

.ta-r {
  text-align: right; }

.td-n {
  text-decoration: none; }

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.short {
  margin-bottom: 5px; }

.bump {
  margin-bottom: 20px; }

.bump--sm {
  margin-bottom: 40px; }

.bump--md {
  margin-bottom: 60px; }

.nudge {
  margin-right: 15px; }

.nudge--sm {
  margin-right: 5px; }

.nudge-left {
  margin-left: 15px; }

@media (min-width: 992px) {
  .push-down--intro {
    padding-top: 90px; } }

.spacer {
  margin-bottom: 8vh; }
  .spacer--large {
    margin-bottom: 8vh; }
    @media (min-width: 768px) {
      .spacer--large {
        margin-bottom: 15vh; } }
  .spacer-top {
    margin-top: 8vh; }
    .spacer-top--large {
      margin-top: 8vh; }
      @media (min-width: 768px) {
        .spacer-top--large {
          margin-top: 15vh; } }

.p-spacer {
  padding-bottom: 8vh; }
  .p-spacer--large {
    padding-bottom: 8vh; }
    @media (min-width: 768px) {
      .p-spacer--large {
        padding-bottom: 15vh; } }
  .p-spacer-top {
    padding-top: 8vh; }
    .p-spacer-top--large {
      padding-top: 8vh; }
      @media (min-width: 768px) {
        .p-spacer-top--large {
          padding-top: 15vh; } }

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.bg-cover {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }

@media (max-width: 992px) {
  .no-bg-sm {
    background-image: none !important; } }

main, .wrapper {
  *zoom: 1; }
  main:before, .wrapper:before, main:after, .wrapper:after {
    content: " ";
    display: table; }
  main:after, .wrapper:after {
    clear: both; }

.clear {
  clear: both; }
  .clear:after {
    content: "";
    display: table;
    clear: both; }

.is-hidden {
  display: none; }

@media (min-width: 768px) {
  .is-hidden--sm {
    display: none !important; } }

@media (min-width: 992px) {
  .is-hidden--md {
    display: none !important; } }

.is-visible {
  display: block; }

.is-visible--md {
  display: none; }
  @media (min-width: 992px) {
    .is-visible--md {
      display: block !important; } }

.img--responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.img--stretch {
  width: 100%; }

.img--slight-border {
  border: solid #F9BE53 2px;
  font-size: 0; }

.img--clip-left-top {
  position: relative; }
  @media (max-width: 768px) {
    .img--clip-left-top {
      max-width: 300px;
      margin: 0 auto; } }
  .img--clip-left-top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 70px 70px 0 0;
    border-color: white transparent transparent  transparent; }
    @media (min-width: 992px) {
      .img--clip-left-top::before {
        border-width: 90px 90px 0 0; } }

.frame {
  width: 100%;
  height: 250px; }
  @media (min-width: 768px) {
    .frame {
      height: 395px; } }
  @media (min-width: 992px) {
    .frame {
      height: 520px; } }
  @media (min-width: 1260px) {
    .frame {
      height: 425px; } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

textarea {
  resize: none; }

html {
  height: 100%; }

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #333333;
  background: white; }
  @media (min-width: 1260px) {
    html, body {
      font-size: 62.5% !important;
      /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */ } }

body {
  position: relative; }
  body.fix {
    overflow: hidden; }

main {
  position: relative;
  z-index: 1; }

h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 25px; }
  h1.fancy, .h1.fancy,
  h2.fancy, .h2.fancy,
  h3.fancy, .h3.fancy {
    font-weight: 300;
    font-family: big-caslon-fb, serif; }
    h1.fancy strong, .h1.fancy strong,
    h2.fancy strong, .h2.fancy strong,
    h3.fancy strong, .h3.fancy strong {
      font-weight: 900;
      font-family: proxima-nova, sans-serif; }

h1, .h1 {
  font-size: 6rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 6.4rem; } }

h2, .h2 {
  font-size: 4rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 4.8rem; } }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.4rem; }

.with-line {
  font-family: big-caslon-fb, serif;
  font-weight: normal;
  position: relative; }
  .with-line span {
    position: relative;
    background-color: white;
    padding-right: 50px; }
  .with-line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
    background-color: #F5A623; }

p, .p {
  font-family: proxima-nova, sans-serif;
  font-size: 1.8rem;
  line-height: 1.7;
  margin-bottom: 20px; }

.small > p,
.small > .p,
.small {
  font-size: 1.6rem; }

.big > p,
.big > .p,
.big {
  font-size: 2.2rem; }

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: #333333;
  font-weight: 400;
  transition: color .3s; }

.link {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.7; }
  .link--purple {
    color: #33003C; }
    .link--purple:hover {
      color: #09000d; }

.link--icon span {
  margin-left: 10px; }

ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px; }
  ul.ul--unstyled, ul.ul--row, ol.ul--unstyled, ol.ul--row {
    list-style: none;
    margin: 0;
    padding: 0; }
    ul.ul--unstyled li, ul.ul--row li, ol.ul--unstyled li, ol.ul--row li {
      padding: 0; }
  ul.ul--row li, ol.ul--row li {
    display: inline-block; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 300;
  color: #333333;
  padding-bottom: 15px; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

.body h2, .body .h2, .body h3, .body .h3, .body h4, .body .h4 {
  margin-top: 25px; }

.body .image, .body .video {
  margin: 35px 0; }

.body .frame {
  width: 100%;
  height: 250px; }
  @media (min-width: 768px) {
    .body .frame {
      height: 370px; } }
  @media (min-width: 992px) {
    .body .frame {
      height: 420px; } }

.news__socials {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .news__socials .p {
    margin-bottom: 0;
    margin-right: 15px; }
  .news__socials li {
    margin-right: 15px; }
    .news__socials li:last-of-type {
      margin-right: 0; }

.tagline__hold {
  font-size: 1.8rem;
  margin-bottom: 15px; }
  .tagline__hold .split {
    margin: 0 10px;
    color: #F5A623; }
    .tagline__hold .split:first-of-type {
      margin: 0 10px 0 0; }
  .tagline__hold .item {
    font-weight: 600; }

.line {
  position: relative;
  display: block; }
  .line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: #F5A623; }
  .line h2, .line .icon, .line p {
    position: relative;
    margin: 0; }
  .line .icon {
    transition: transform .3s; }
  .line h2, .line p {
    display: inline-block;
    background-color: white;
    padding-right: 50px;
    font-weight: 700;
    font-size: 2.2rem; }
  .line.ta-c h2, .line.ta-c p {
    padding-left: 50px; }
  .line h2 {
    font-family: proxima-nova, sans-serif; }
  .line .arrow {
    position: absolute;
    right: 0;
    top: 0;
    opacity: .5;
    transition: opacity .3s; }
  .line--link::before {
    width: calc(100% - 30px); }
  .line--link:hover .arrow, .line--link:hover::before {
    opacity: 1; }
  .line--link:hover .icon {
    transform: scale(1.2); }

.share {
  position: relative;
  width: 100%; }
  .share .pinit {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px; }
    .share .pinit img {
      width: 100%;
      transition: transform .3s; }
    .share .pinit:hover img {
      transform: scale(1.2); }

.color--white {
  color: white; }
  .color--white > * {
    color: white; }

a {
  font-weight: 600; }
  a.thin {
    font-weight: 400; }

.icon {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  background-size: contain; }
  .icon > svg {
    display: block; }
  .icon path, .icon polygon {
    transition: fill .3s; }

.icon--white path {
  fill: white; }

.icon--black path, .icon--black polygon {
  fill: black !important; }

.icon--black path, .icon--black polygon, .icon--black rect, .icon--black circle {
  fill: #333333; }

.icon--light path, .icon--light polygon, .icon--light rect, .icon--light circle {
  fill: #F1EEED; }

.icon--grey path, .icon--grey polygon, .icon--grey rect, .icon--grey circle {
  fill: #5f5f5f; }

.icon--dark path, .icon--dark polygon, .icon--dark rect, .icon--dark circle {
  fill: #3a3a3a; }

.icon--green-success path, .icon--green-success polygon, .icon--green-success rect, .icon--green-success circle {
  fill: #32b292; }

.icon--red-error path, .icon--red-error polygon, .icon--red-error rect, .icon--red-error circle {
  fill: #EE3D52; }

.icon--brand-yellow path, .icon--brand-yellow polygon, .icon--brand-yellow rect, .icon--brand-yellow circle {
  fill: #F5A623; }

.icon--brand-grey path, .icon--brand-grey polygon, .icon--brand-grey rect, .icon--brand-grey circle {
  fill: #4A4A4A; }

.icon--10,
.icon--10 > svg {
  width: 10px;
  height: 10px; }

.icon--20,
.icon--20 > svg {
  width: 20px;
  height: 20px; }

.icon--25,
.icon--25 > svg {
  width: 25px;
  height: 25px; }

.icon--30,
.icon--30 > svg {
  width: 30px;
  height: 30px; }

.icon--40,
.icon--40 > svg {
  width: 40px;
  height: 40px; }

.icon--50,
.icon--50 > svg {
  width: 50px;
  height: 50px; }

.icon--60,
.icon--60 > svg {
  width: 60px;
  height: 60px; }

.icon--logo,
.icon--logo > svg {
  width: 130px;
  height: 36px; }
  @media (min-width: 768px) {
    .icon--logo,
    .icon--logo > svg {
      width: 202px;
      height: 56px; } }

.icon--logo-footer,
.icon--logo-footer > svg {
  width: 202px;
  height: 56px; }

.icon--quote,
.icon--quote > svg {
  width: 140px;
  height: 76px; }

.icon--facebook svg > *, .icon--twitter svg > *, .icon--instagram svg > *, .icon--mail svg > * {
  fill: #333333; }

a:hover .icon--facebook svg > * {
  fill: #3b5998; }

a:hover .icon--twitter svg > * {
  fill: #00b6f1; }

a:hover .icon--instagram svg > * {
  fill: #c32aa3; }

a:hover .icon--mail svg > * {
  fill: black; }

.social {
  position: relative; }
  .social span {
    position: relative; }
  .social::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(130deg);
    width: 0;
    height: 1px;
    background-color: #33003C;
    opacity: 0;
    transition: width .3s, opacity .3s; }
  .social:hover::before {
    opacity: 1;
    width: 80px; }

input {
  border: solid 1px black;
  border-radius: 0px;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  transition: background .3s, border .5s; }
  input:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb; }
  input:not(:placeholder-shown) {
    background-color: #fbfbfb; }
  input[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed; }

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0; }

.input--hold {
  margin-bottom: 20px; }

textarea {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s; }
  textarea:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb; }
  textarea:not(:empty) {
    background-color: #fbfbfb; }

select {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px 40px 15px 15px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */ }

input[type=file] {
  position: relative;
  background-color: transparent;
  cursor: pointer;
  padding: 14px 0;
  width: 100%;
  color: #333333;
  border: 0;
  border-bottom: 2px solid black;
  margin-bottom: 5px; }
  input[type=file]::-webkit-file-upload-button {
    visibility: hidden; }
  input[type=file]::before {
    content: 'Select a file';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #bfbfbf;
    white-space: nowrap;
    font-size: 1.6rem;
    pointer-events: none; }
  input[type=file]:after {
    content: 'Choose file';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 1.4rem;
    background: #F5A623;
    color: white;
    padding: 10px 16px; }

.dropdown {
  position: relative;
  display: inline-block;
  width: 350px; }
  .dropdown .trigger {
    cursor: pointer;
    position: relative;
    padding: 18px 40px 15px 0;
    font-size: 1.8rem;
    border-bottom: 1px solid #4A4A49; }
    .dropdown .trigger .arrow {
      pointer-events: none;
      position: absolute;
      right: 6px;
      top: 9px;
      transition: transform .3s; }
  .dropdown .sub-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(211, 211, 211, 0.5);
    transition: opacity .3s, visibility .3s; }
    .dropdown .sub-menu ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .dropdown .sub-menu ul li:last-of-type {
        padding-bottom: 0; }
    .dropdown .sub-menu a {
      display: block;
      transition: transform .3s; }
      .dropdown .sub-menu a:hover {
        transform: translateX(15px); }
  .dropdown.open .sub-menu {
    opacity: 1;
    visibility: visible; }
  .dropdown.open .trigger .arrow {
    transform: scaleY(-1); }

.file-label {
  font-weight: 400;
  font-size: 18px;
  display: block;
  margin-bottom: 5px; }

.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative; }
  .input__hold--fancy label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px; }
    .input__hold--fancy label span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e; }
  .input__hold--fancy input, .input__hold--fancy textarea, .input__hold--fancy select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333; }
  .input__hold--fancy input:focus ~ label, .input__hold--fancy input.active--input ~ label {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    letter-spacing: 1px;
    font-size: 14px; }
  .input__hold--fancy input[type=file] {
    border: 0;
    padding-top: 0;
    width: 100%;
    display: flex; }
  .input__hold--fancy textarea:focus ~ label, .input__hold--fancy textarea.active--input ~ label {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    font-size: 14px; }
  .input__hold--fancy select {
    width: 100%;
    font-size: 18px;
    transition: border .5s; }
  .input__hold--fancy.active--input select {
    border-bottom: 2px solid white; }
    .input__hold--fancy.active--input select ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px; }

#firstName {
  display: none; }

.checkbox {
  display: flex; }
  .checkbox label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.3;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px; }
    .checkbox label::before, .checkbox label::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block; }
    .checkbox label::before {
      height: 16px;
      width: 16px;
      border: 1px solid #e9dcd4;
      left: 0;
      background-color: #e9dcd4;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px; }
    .checkbox label::after {
      color: white;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px; }
  .checkbox input[type="checkbox"] + label::after {
    content: none; }
  .checkbox input[type="checkbox"]:checked + label:before {
    background-color: #F5A623;
    border-color: #F5A623; }
  .checkbox input[type="checkbox"]:checked + label::after {
    content: ""; }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline: #3b99fc auto 5px; }
  .checkbox input[type="checkbox"]:disabled ~ label:before, .checkbox input[type="checkbox"]:disabled ~ label:after {
    opacity: .5; }
  .checkbox.error label::before, .checkbox.error label::after {
    animation: shake .5s ease-in-out forwards; }

.form-hold {
  position: relative; }
  .form-hold .form-container {
    transition: opacity .3s; }
  .form-hold .form-complete {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition-delay: .5s; }
  .form-hold--complete .form-container {
    opacity: 0;
    visibility: hidden; }
  .form-hold--complete .form-complete {
    opacity: 1;
    visibility: visible; }

.input--large-search form, .input--large-search .form {
  width: 100%;
  display: flex; }

.input--large-search input {
  color: white;
  background-color: transparent;
  font-size: 3rem;
  font-family: big-caslon-fb, serif;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  line-height: 1;
  padding: 0 15px 10px 0;
  width: calc(100% - 70px); }
  .input--large-search input::placeholder {
    color: white;
    opacity: 1; }
  .input--large-search input:focus {
    border-color: white; }
  .input--large-search input.active--input {
    border-bottom: 1px solid #F5A623; }
    .input--large-search input.active--input + button {
      opacity: 1; }
  .input--large-search input:-ms-input-placeholder, .input--large-search input::-ms-input-placeholder {
    color: white; }
  .input--large-search input:valid ~ button {
    background-color: #F5A623; }

.input--large-search button {
  cursor: pointer;
  background-color: transparent;
  float: right;
  border: none;
  outline: none;
  font-size: 1.8rem;
  font-family: big-caslon-fb, serif;
  border-radius: 0;
  padding: 10px;
  width: 70px;
  opacity: 0;
  transition: all .3s; }
  .input--large-search button svg path {
    fill: white; }
  .input--large-search button .icon,
  .input--large-search button .icon > svg {
    width: 20px;
    height: 20px; }

.input--large-search-page {
  border-color: #333; }
  .input--large-search-page input {
    color: black;
    max-width: 600px;
    border-top: 1px solid #4A4A4A;
    border-left: 1px solid #4A4A4A;
    border-bottom: 1px solid #4A4A4A;
    padding: 15px; }
    .input--large-search-page input::placeholder {
      color: #333;
      opacity: 1; }
    .input--large-search-page input.active--input {
      border-color: #F5A623; }
    .input--large-search-page input:-ms-input-placeholder, .input--large-search-page input::-ms-input-placeholder {
      color: #333; }
  .input--large-search-page button {
    opacity: 1;
    background-color: #4A4A4A; }
  .input--large-search-page button svg path {
    fill: white; }

.search-hold p {
  margin-bottom: 7px; }

.form--hold {
  display: flex;
  margin-bottom: 10px; }
  .form--hold .email, .form--hold .button {
    padding: 0 0 10px;
    border: none;
    border-bottom: 1px solid white;
    background-color: transparent; }
  .form--hold .email {
    flex: 1;
    color: white; }
  .form--hold .button {
    color: #ffad25;
    font-weight: 700; }

.input--success {
  border-color: #32b292; }

.input--error {
  border-color: #EE3D52; }

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: #33003C;
  outline: none;
  background-color: transparent;
  border: 2px solid #33003C;
  padding: 15px 45px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;
  position: relative; }
  .btn:hover, .btn:focus {
    color: #fff;
    background-color: #33003C; }
    .btn:hover svg path, .btn:focus svg path {
      fill: white; }
  .btn::before {
    content: "";
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(130deg);
    width: 0;
    height: 1px;
    background-color: #F5A623;
    opacity: 0;
    transition: width .3s, opacity .3s; }
  .btn:hover::before {
    opacity: 1;
    width: 120px; }

.btn--full {
  width: 100%; }

.btn--r {
  float: right; }

.btn--white {
  border-color: white;
  color: white; }

.btn--white-fill {
  border-color: white;
  background-color: white;
  color: black; }

.btn--simple {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: white;
  outline: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 15px 45px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none; }
  .btn--simple:hover, .btn--simple:focus {
    color: #33003C;
    background-color: white;
    border-color: white; }
    .btn--simple:hover svg path, .btn--simple:focus svg path {
      fill: white; }

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px; }

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px; }

/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/
.g {
  width: 100%;
  lost-flex-container: row; }
  .g > * {
    width: 100%; }

.g--ng > * {
  width: 50%;
  flex-basis: 50%; }

/*
  Footer grid modification
*/
.g--gutter .g__1-2 {
  lost-column: 1/2 2 1.33334rem; }

.g--gutter .g__1-3 {
  lost-column: 1/3 3 1.33334rem; }

.g--gutter .g__2-3 {
  lost-column: 2/3 3 1.33334rem; }

.g__c {
  justify-content: center; }

.g__ac {
  align-items: center; }

@media (min-width: 768px) {
  .gs__ac {
    align-items: center; }
  .gs__m1-2 {
    lost-move: 1/2 2 6.66667rem; }
  .gs__m-1-2 {
    lost-move: -1/2 2 6.66667rem; }
  .gs__1-2 {
    lost-column: 1/2; }
  .g--gutter .gs__1-2 {
    lost-column: 1/2 2 6.66667rem; }
  .g--gutter .gs__1-3 {
    lost-column: 1/3 3 6.66667rem; }
  .g--gutter .gs__2-3 {
    lost-column: 2/3 3 6.66667rem; }
  .g--gutter-lg .gs__1-2 {
    lost-column: 1/2 2 16rem; } }

@media (min-width: 992px) {
  .gm__ac {
    align-items: center; }
  .gm__1-3 {
    lost-column: 1/3; }
  .gm__1-2 {
    lost-column: 1/2; }
  .gm__1-4 {
    lost-column: 1/4; }
  .gm__31-100 {
    lost-column: 31/100; }
  .gm__23-100 {
    lost-column: 23/100; }
  .g--gutter .gm__1-4 {
    lost-column: 1/4 4 6.66667rem; }
  .g--gutter .gm__1-6 {
    lost-column: 1/6 6 6.66667rem; }
  .g--gutter-lg .gm__1-2 {
    lost-column: 1/2 2 22.66667rem; } }

@media (min-width: 1260px) {
  .g--gutter .gl__1-3 {
    lost-column: 1/3 3 5.33334rem; }
  .gl__1-3 {
    lost-column: 1/3 0 0 flex; }
  .gl__1-4 {
    lost-column: 1/4; } }

.wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0 1.33334rem; }
  @media (min-width: 768px) {
    .wrapper {
      width: 720px; } }
  @media (min-width: 992px) {
    .wrapper {
      width: 940px; } }
  @media (min-width: 1260px) {
    .wrapper {
      width: 1260px; } }

@media (min-width: 1260px) {
  .wrapper--tight {
    width: 960px; } }

@media (min-width: 992px) {
  .wrapper--x-tight {
    width: 780px; } }

@media (min-width: 768px) {
  .wrapper--full {
    width: 100%; } }

#header {
  background-color: #F1EEED;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }
  #header .text path {
    fill: #4A4A49; }
  #header .logo {
    position: relative;
    z-index: 998;
    display: inline-block;
    padding: 15px 1.33334rem; }
  #header .navigation-burger__panel, #header .navigation__socials, #header .navigation__news {
    display: none; }
  #header .navigation-burger {
    padding: 20px 1.33334rem; }
  #header .navigation {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100svh;
    background-color: #4A4A49;
    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
    transition: opacity .2s linear, visibility .7s; }
    #header .navigation .navigation__inner {
      margin-top: 80px;
      padding-bottom: 100px; }
    #header .navigation .ul--nav {
      list-style: none; }
      #header .navigation .ul--nav:first-of-type {
        padding: 30px 30px 0;
        display: inline-block; }
      #header .navigation .ul--nav:last-of-type {
        padding: 0 30px 30px; }
      #header .navigation .ul--nav li {
        display: block;
        white-space: nowrap;
        margin-bottom: 35px; }
      #header .navigation .ul--nav a {
        position: relative;
        color: white;
        font-size: 3rem;
        line-height: 1;
        font-family: big-caslon-fb, serif;
        font-weight: 300;
        transition: color .3s, background-color .3s, padding .3s; }
    #header .navigation .dropdown__hold a.active {
      padding-right: 40px; }
    #header .navigation .dropdown {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.15s ease-out;
      min-height: 100%;
      margin: 0 -30px 0;
      background-color: #F5A623; }
      #header .navigation .dropdown ul {
        margin: 0;
        padding: 30px 30px 30px 60px; }
        #header .navigation .dropdown ul li:last-of-type {
          margin-bottom: 0; }
      #header .navigation .dropdown a {
        color: #4A4A4A;
        font-weight: bold;
        font-family: proxima-nova, sans-serif;
        font-size: 2.2rem; }
      #header .navigation .dropdown.open {
        transition: max-height 0.25s ease-in;
        max-height: 500px; }
  #header.open .logo::before {
    opacity: 0 !important; }
  #header.open .text path {
    fill: white !important; }
  #header.open .navigation {
    opacity: 1;
    visibility: visible;
    transition: opacity .2s linear, visibility .7s, transform .3s; }

@media (min-width: 768px) {
  #header {
    background-color: transparent; }
    #header .logo--reverse .text path {
      fill: white; }
    #header .navigation {
      background-size: 100%;
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("/dist/img/bkg/cast-stone-menu.jpg"); }
      #header .navigation::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #242424;
        opacity: .8; }
      #header .navigation .navigation__inner {
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        padding-bottom: 0; }
        #header .navigation .navigation__inner a {
          position: relative; }
          #header .navigation .navigation__inner a:hover {
            color: #F5A623; }
      #header .navigation .ul--nav {
        padding: 30px 0 30px 30px; }
        #header .navigation .ul--nav:first-of-type {
          padding: 30px 0 0 30px; }
        #header .navigation .ul--nav:last-of-type {
          padding: 0 0 30px 30px; }
      #header .navigation .navigation__inner .dropdown__hold {
        position: relative; }
        #header .navigation .navigation__inner .dropdown__hold > a:hover {
          color: white; }
        #header .navigation .navigation__inner .dropdown__hold .dropdown {
          position: absolute;
          left: 100%;
          padding-left: 150px;
          top: 0;
          background-color: transparent;
          max-height: initial;
          margin: 0;
          width: auto;
          pointer-events: none; }
          #header .navigation .navigation__inner .dropdown__hold .dropdown ul {
            padding: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s 0s, visibility .3s 0s; }
          #header .navigation .navigation__inner .dropdown__hold .dropdown a {
            font-weight: 300;
            color: #D8D8D8; }
            #header .navigation .navigation__inner .dropdown__hold .dropdown a::before {
              content: none; }
            #header .navigation .navigation__inner .dropdown__hold .dropdown a:hover {
              color: #F5A623; }
          #header .navigation .navigation__inner .dropdown__hold .dropdown::before, #header .navigation .navigation__inner .dropdown__hold .dropdown::after {
            opacity: 0;
            transition: opacity .3s; }
          #header .navigation .navigation__inner .dropdown__hold .dropdown::before {
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            height: 1px;
            background-color: #F5A623;
            width: 0;
            transform: none;
            transition: opacity .3s, width .3s; }
          #header .navigation .navigation__inner .dropdown__hold .dropdown::after {
            content: "•";
            color: #F5A623;
            position: absolute;
            left: 127px;
            font-size: 39px;
            line-height: 1;
            top: 0;
            margin-left: 0;
            margin-top: -10px;
            transition: opacity .3s 0s; }
        #header .navigation .navigation__inner .dropdown__hold > a {
          display: block;
          width: 100%;
          padding-right: 15px; }
          #header .navigation .navigation__inner .dropdown__hold > a::before {
            content: none; }
        #header .navigation .navigation__inner .dropdown__hold:hover .dropdown {
          pointer-events: all;
          z-index: 1; }
          #header .navigation .navigation__inner .dropdown__hold:hover .dropdown ul {
            visibility: visible;
            opacity: 1;
            transition: opacity .3s .8s, visibility .3s .8s; }
          #header .navigation .navigation__inner .dropdown__hold:hover .dropdown::before, #header .navigation .navigation__inner .dropdown__hold:hover .dropdown::after {
            opacity: 1; }
          #header .navigation .navigation__inner .dropdown__hold:hover .dropdown::before {
            width: 130px;
            opacity: 1; }
          #header .navigation .navigation__inner .dropdown__hold:hover .dropdown::after {
            transition: opacity .3s .4s; }
      #header .navigation .navigation__socials {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: white;
        padding: 25px;
        width: 40%;
        max-width: 600px;
        text-align: right; }
        #header .navigation .navigation__socials::before {
          content: "";
          position: absolute;
          top: 0;
          right: 100%;
          bottom: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 90px 90px;
          border-color: transparent transparent white transparent; }
        #header .navigation .navigation__socials li {
          margin-right: 15px; }
          #header .navigation .navigation__socials li:last-of-type {
            margin-right: 0; }
    #header .navigation-burger__panel {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      background-color: #4A4A4A;
      clip-path: polygon(100% 0, 100% 100%, 20% 100%, 0 80%, 0 0);
      height: 65px;
      width: 65px;
      opacity: 0;
      transition: opacity .3s; }
    #header .logo {
      position: relative; }
      #header .logo a {
        position: relative; }
      #header .logo::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        clip-path: polygon(100% 0, 100% 70%, 85% 100%, 0 100%, 0 0);
        background-color: #F1EEED;
        opacity: 0;
        transition: opacity .3s; }
  .scrolled #header .logo--reverse .text path {
    fill: #4A4A49; }
  .scrolled #header .navigation-burger__panel, .scrolled #header .logo::before {
    opacity: 1; } }

@media (min-width: 992px) {
  #header .navigation .navigation__inner {
    left: 45%;
    z-index: 1; }
    #header .navigation .navigation__inner .dropdown__hold .dropdown {
      padding-left: 70px; }
      #header .navigation .navigation__inner .dropdown__hold .dropdown::after {
        left: 50px; }
      #header .navigation .navigation__inner .dropdown__hold .dropdown.open::before {
        width: 53px; }
    #header .navigation .navigation__inner .dropdown__hold:hover .dropdown::before {
      width: 53px;
      opacity: 1; }
  #header .navigation .ul--nav {
    padding: 30px 0 30px 0; }
    #header .navigation .ul--nav > li > a {
      font-size: 3.2rem; }
  #header .navigation .navigation {
    overflow: hidden; }
  #header .navigation .navigation__news {
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    padding: 30px 50px 30px 100px;
    color: white; }
    #header .navigation .navigation__news .news_block {
      color: white;
      display: block;
      max-width: 245px;
      margin-bottom: 50px; }
      #header .navigation .navigation__news .news_block .icon {
        transition: transform .3s; }
      #header .navigation .navigation__news .news_block:last-of-type {
        margin-bottom: 0; }
      #header .navigation .navigation__news .news_block .heading {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 1.8rem; }
      #header .navigation .navigation__news .news_block p {
        margin-bottom: 10px;
        font-size: 1.6rem;
        font-weight: 400; }
      #header .navigation .navigation__news .news_block:hover .icon {
        transform: translateX(10px); }
        #header .navigation .navigation__news .news_block:hover .icon svg path {
          fill: #F5A623; } }

@media (min-width: 1260px) {
  #header .navigation .navigation__news .news_block {
    max-width: 280px; }
  #header .navigation .navigation__inner .dropdown__hold:hover .dropdown::before {
    width: 130px;
    opacity: 1; }
  #header .navigation .navigation__inner .dropdown__hold .dropdown {
    padding-left: 150px; }
    #header .navigation .navigation__inner .dropdown__hold .dropdown::after {
      left: 127px; }
    #header .navigation .navigation__inner .dropdown__hold .dropdown:hover::before {
      width: 130px; } }

#footer {
  background-color: #4A4A4A;
  color: white; }
  #footer .heading {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 20px; }
  #footer .footer__logo {
    padding: 20px;
    text-align: center; }
  #footer .footer__social {
    padding: 20px; }
    #footer .footer__social li {
      margin-right: 15px; }
  #footer .footer__inner {
    padding-top: 40px; }
    #footer .footer__inner a {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      transition: all .3s; }
      #footer .footer__inner a:hover {
        border-color: #F5A623; }
  #footer ul {
    margin: 0;
    list-style: none;
    padding-top: 0; }
    #footer ul li {
      padding-bottom: 5px; }
  #footer p, #footer a {
    color: white;
    font-size: 1.6rem; }
  #footer .footer__copy {
    padding-top: 20px; }

@media (min-width: 768px) {
  #footer .footer__top {
    display: flex; }
  #footer .footer__social, #footer .footer__logo {
    flex: 1; }
  #footer .footer__social {
    position: relative;
    padding: 20px 0;
    background-color: white; }
    #footer .footer__social li {
      margin-right: 15px; }
    #footer .footer__social::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 100vw;
      background-color: white; }
    #footer .footer__social::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 85px 85px;
      border-color: transparent transparent #4A4A4A transparent; }
  #footer .footer__logo {
    text-align: right;
    padding: 20px 0 0 0; }
  #footer .footer__inner {
    padding-top: 70px; }
  #footer .footer__copy {
    padding-top: 50px; } }

.chunk {
  display: block;
  clear: both;
  position: relative;
  overflow: hidden; }
  .chunk:after {
    content: "";
    display: table;
    clear: both; }
  .chunk--ov {
    overflow: visible; }

.chunk--grey-light {
  background-color: #F1EEED; }
  .chunk--grey-light .img--clip-left-top::before {
    border-color: #F1EEED transparent transparent transparent; }

.chunk--yellow {
  background-color: #FFF0D6; }

.chunk--block {
  padding: 15px; }
  @media (min-width: 992px) {
    .chunk--block {
      padding: 50px; } }

.tick {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 35px; }
  .tick::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("/dist/img/icons/tick.svg");
    background-size: 28px 28px;
    height: 28px;
    width: 28px; }

.hero {
  position: relative;
  width: 100%; }
  .hero h1, .hero .h1 {
    font-family: big-caslon-fb, serif;
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
    .hero h1 strong, .hero .h1 strong {
      line-height: 1;
      display: inline-block;
      font-weight: 300;
      border-bottom: 1px solid #F5A623; }
  .hero .hero__socials {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 15px;
    max-width: 600px;
    text-align: right; }
    .hero .hero__socials .line {
      display: none; }
    .hero .hero__socials::before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 70px 70px;
      border-color: transparent transparent white transparent; }
    .hero .hero__socials li {
      margin-right: 15px; }
      .hero .hero__socials li:last-of-type {
        margin-right: 0; }
  .hero--error {
    background-color: #F1EEED; }
    .hero--error .hero__socials::after {
      content: none !important; }
  .hero--full {
    min-height: 100vh !important; }
    .hero--full .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%); }

.hero--slim {
  padding-top: 150px;
  background-color: #F1EEED; }
  .hero--slim h1 {
    display: inline-block;
    margin-bottom: 40px; }

.hero--select h1 {
  margin-bottom: 20px; }

.hero--select .dropdown {
  margin-bottom: 40px; }
  .hero--select .dropdown .trigger {
    overflow: hidden; }

.hero--media {
  position: relative;
  padding-top: 150px;
  padding-bottom: 110px;
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  color: white; }
  .hero--media::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc000000', endColorstr='#80000000', GradientType=0); }
  .hero--media h1 {
    display: inline-block;
    border-bottom: 1px solid #F5A623; }

.hero__success {
  border-top: 3px solid white;
  padding-top: 15px;
  background-color: #138113; }
  .hero__success p {
    color: white; }

.hero__bar {
  border-top: 3px solid white;
  padding-top: 10px;
  padding-bottom: 10px; }

@media (min-width: 768px) {
  .hero--select {
    padding-bottom: 40px; }
    .hero--select .wrapper {
      display: flex;
      align-items: center; }
    .hero--select .dropdown {
      margin-left: auto; }
    .hero--select h1, .hero--select .h1, .hero--select .dropdown {
      margin-bottom: 0; }
  .hero--media .hero__socials {
    width: 40%; } }

@media (min-width: 992px) {
  .hero h1, .hero .h1 {
    font-size: 7rem; }
  .hero .hero__socials {
    padding: 25px; }
    .hero .hero__socials::before {
      border-width: 0 0 90px 90px; }
    .hero .hero__socials .line {
      display: block;
      height: 270px;
      position: absolute;
      width: 1px;
      background-color: #F5A623;
      left: -476px;
      top: -5px;
      transform: translateY(-50%) rotate(45deg);
      transform-origin: center center; }
    .hero .hero__socials::after {
      content: "";
      animation: .5s ease-out 0s 1 lineIn;
      height: 390px;
      position: absolute;
      width: 1px;
      background-color: #F5A623;
      left: -45px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      transform-origin: center center; }
  .hero--slim {
    padding-top: 190px; }
  .hero--media {
    padding-top: 220px;
    padding-bottom: 180px; } }

@media (min-width: 1260px) {
  .hero--media {
    padding-top: 260px;
    padding-bottom: 200px; }
    .hero--media .hero__socials .line {
      left: -676px; } }

@keyframes lineIn {
  0% {
    height: 0; }
  100% {
    height: 390px; } }

@supports (-ms-ime-align: auto) {
  .hero--media {
    overflow: hidden; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero--media {
    overflow: hidden; } }

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: #F1EEED; }
  #cookie-message p {
    color: #333; }
  #cookie-message .wrapper {
    padding: 2.66667rem 1.33334rem; }

@media (min-width: 992px) {
  #cookie-message .wrapper {
    padding: 40px; } }

.pagination {
  text-align: center;
  position: relative; }
  .pagination::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: #F5A623; }
  .pagination a, .pagination span {
    font-weight: 700;
    position: relative;
    display: inline-block;
    height: 30px;
    width: 30px;
    padding-top: 2px;
    margin: 0 8px;
    color: black;
    background-color: white;
    border-radius: 50%; }
    @media (min-width: 992px) {
      .pagination a, .pagination span {
        margin: 0 12px; } }
    @media (min-width: 1260px) {
      .pagination a, .pagination span {
        padding-top: 0; } }
    .pagination a.current, .pagination span.current {
      background-color: #F5A623;
      color: white; }
  .pagination .icon {
    padding: 4px;
    opacity: .5;
    margin: 0;
    transition: opacity .5s; }
    .pagination .icon:hover {
      opacity: 1; }
  .pagination .previous {
    margin-right: 10px; }
  .pagination .next {
    margin-left: 10px; }
  @media (min-width: 992px) {
    .pagination .previous {
      margin-right: 20px; }
    .pagination .next {
      margin-left: 20px; } }

.navigation-burger {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 5px 0;
  float: right; }

.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 22px;
  margin: 0;
  position: relative;
  width: 35px;
  outline: none; }
  .hamburger span {
    height: 0;
    border-bottom: 2px solid #F5A623;
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(0);
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden; }
  .hamburger:hover span {
    width: 100%;
    transform: translateY(-50%) translateX(0); }
  .hamburger:after, .hamburger:before {
    transform: translateY(-50%);
    position: absolute;
    border-bottom: 2px solid #F5A623;
    content: '';
    height: 0;
    width: 100%;
    right: 0;
    transition: all .3s ease-out; }
  .hamburger:before {
    top: 0; }
  .hamburger:after {
    top: 100%; }
  .hamburger.open span {
    width: 100%;
    transform: translateY(-50%) rotate(45deg) translateX(0); }
  .hamburger.open:before {
    top: 50%;
    transform: translateY(-50%) rotate(135deg) translateX(0); }
  .hamburger.open:after {
    top: 50%;
    transform: translateY(-50%) rotate(225deg) translateX(0); }

.header--flip .hamburger span, .header--flip .hamburger:before, .header--flip .hamburger:after {
  border-color: #F5A623; }

.tile--tech {
  display: block;
  margin-bottom: 40px; }
  .tile--tech .inner {
    padding: 20px 0; }
  .tile--tech .p {
    font-weight: bold; }
  .tile--tech .link {
    display: flex;
    align-items: center; }
    .tile--tech .link .p {
      margin-bottom: 0;
      padding-right: 10px; }
    .tile--tech .link .line {
      opacity: .5;
      position: relative;
      padding-left: 100px;
      display: inline-block;
      transition: padding .3s, opacity .3s; }
      .tile--tech .link .line .icon {
        transition: transform .3s; }
      .tile--tech .link .line::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        background-color: #F5A623; }
  .tile--tech:hover .line, .tile--tech:focus .line {
    opacity: 1;
    padding-left: 120px; }
    .tile--tech:hover .line .icon, .tile--tech:focus .line .icon {
      transform: scale(1.2); }

.quote__block .quote {
  position: relative; }
  .quote__block .quote::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 1px;
    background-color: #F5A623; }
  .quote__block .quote > p {
    font-family: big-caslon-fb, serif;
    font-weight: 300;
    font-size: 2.5rem; }

.tile--news, .tile--study {
  position: relative;
  min-height: 440px;
  margin-bottom: 35px;
  display: block;
  background-color: black;
  color: white; }
  .tile--news .bkg, .tile--study .bkg {
    background-size: 100%;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .tile--news .bkg::before, .tile--study .bkg::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .55;
      transition: opacity .3s; }
  .tile--news .inner, .tile--study .inner {
    position: relative; }
  .tile--news .arrow, .tile--study .arrow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding: 30px; }
    .tile--news .arrow .icon, .tile--study .arrow .icon {
      transition: transform .3s; }
    .tile--news .arrow::before, .tile--study .arrow::before {
      content: "";
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: 0;
      background-color: #F5A623;
      transition: width .3s; }
  .tile--news:hover .arrow .icon, .tile--news:focus .arrow .icon, .tile--study:hover .arrow .icon, .tile--study:focus .arrow .icon {
    transform: translateX(15px); }
  .tile--news:hover .arrow::before, .tile--news:focus .arrow::before, .tile--study:hover .arrow::before, .tile--study:focus .arrow::before {
    width: calc(100% - 15px); }
  .tile--news:hover .bkg::before, .tile--news:focus .bkg::before, .tile--study:hover .bkg::before, .tile--study:focus .bkg::before {
    opacity: .9; }

.tile--news {
  padding: 30px; }
  .tile--news h3 {
    font-size: 2.2rem;
    display: inline-block;
    padding-bottom: 10px;
    border-bottom: 1px solid #F5A623; }

.tile--study-text {
  font-weight: 400; }
  .tile--study-text .h4 {
    font-weight: bold;
    margin-bottom: 5px; }

.tile--product-item {
  position: relative;
  min-height: 270px;
  margin-bottom: 35px;
  display: block;
  background-color: black;
  color: white; }
  .tile--product-item .bkg {
    background-size: 100%;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .tile--product-item .bkg::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .55;
      transition: opacity .3s; }
  .tile--product-item .inner {
    position: relative; }
  .tile--product-item .inner {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 10px 30px; }
  .tile--product-item h3 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 2.4rem;
    display: inline-block; }
    .tile--product-item h3::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 1px;
      background-color: #F5A623;
      transition: width .3s; }
  .tile--product-item:hover .bkg::before {
    opacity: .85; }
  @media (min-width: 992px) {
    .tile--product-item {
      min-height: 285px; } }

.tile--study .inner {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 10px 30px; }

.tile--study h3 {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 2.2rem;
  display: inline-block; }
  .tile--study h3::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    background-color: #F5A623;
    transition: width .3s; }

.tile--study:hover h3::before {
  width: 100%; }

.tile--study-slim {
  min-height: 240px;
  max-width: 345px; }

.tile--product {
  position: relative;
  min-height: 440px;
  margin-bottom: 35px;
  display: block;
  background-color: black;
  color: white; }
  .tile--product .bkg {
    background-size: 100%;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .tile--product .bkg::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .55;
      transition: opacity .3s; }
  .tile--product .inner {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }
  .tile--product h3 {
    width: 100%;
    position: relative;
    font-size: 2.8rem;
    display: inline-block;
    padding: 15px 30px; }
    .tile--product h3::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 1px;
      background-color: #F5A623;
      transition: width .3s; }
  .tile--product:hover .bkg::before, .tile--product:focus .bkg::before {
    opacity: .9; }
  .tile--product:hover h3::before, .tile--product:focus h3::before {
    width: 100%; }

.team {
  margin-bottom: 20px; }
  .team .team__name {
    font-family: big-caslon-fb, serif;
    font-weight: 300;
    font-size: 2.2rem; }

.tile--partner img {
  padding: 20px;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

@media (min-width: 992px) {
  .quote__block .quote::before {
    width: 120px; }
  .quote__block .quote > p {
    font-size: 3rem; } }

.cta .cta__links a {
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  transition: border .3s; }
  .cta .cta__links a:first-of-type {
    margin-right: 30px; }
  .cta .cta__links a:hover, .cta .cta__links a:focus {
    border-color: #F5A623; }

@media (min-width: 400px) {
  .carousel-cell {
    max-height: 250px; } }

@media (min-width: 700px) {
  .carousel-cell {
    max-height: 438px; } }

@media (min-width: 1200px) {
  .carousel-cell {
    max-height: 750px; } }

/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 30%;
  top: 20%;
  width: 40%;
  height: 60%; }

.flickity-button {
  background-color: #F5A623;
  opacity: 0.85;
  width: 80px;
  height: 80px;
  transition: opacity .3s; }
  .flickity-button.previous {
    left: -10px; }
  .flickity-button.next {
    right: -10px; }
  .flickity-button svg path {
    fill: white; }
  .flickity-button:hover {
    background-color: #F5A623;
    opacity: 1; }
  @media (min-width: 768px) {
    .flickity-button.previous {
      left: -40px; }
    .flickity-button.next {
      right: -40px; } }

.flickity-prev-next-button {
  border-radius: 0; }

@media (min-width: 768px) {
  .swatch__counter {
    padding-top: 20px; } }

.swatches__item {
  width: 50%; }
  .swatches__item img {
    padding: 0 10px; }
  @media (min-width: 768px) {
    .swatches__item {
      width: 33%; } }
  @media (min-width: 992px) {
    .swatches__item {
      width: 25%; } }
  .swatches__item .swatch__title {
    font-size: 2rem;
    font-weight: 600;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px 30px; }
    @media (min-width: 992px) {
      .swatches__item .swatch__title {
        font-size: 3.2rem; } }

.logo-carousel {
  padding: 0 40px; }
  .logo-carousel .flickity-button {
    width: 40px;
    height: 40px; }
  .logo-carousel .item {
    width: 50%; }
    .logo-carousel .item .tile--partner {
      display: flex;
      width: 100%;
      height: 160px;
      align-items: center; }
    .logo-carousel .item img {
      max-height: 150px; }
    @media (min-width: 768px) {
      .logo-carousel .item {
        width: 33.33%; } }
    @media (min-width: 992px) {
      .logo-carousel .item {
        width: 25%; } }
    @media (min-width: 1260px) {
      .logo-carousel .item {
        width: 16.66%; } }

.product-items-carousel {
  padding: 0 0; }
  .product-items-carousel .flickity-button {
    width: 40px;
    height: 40px; }
  .product-items-carousel .item {
    width: 48%;
    margin-right: 4%; }
    @media (min-width: 768px) {
      .product-items-carousel .item {
        width: 31.33%;
        margin-right: 3%; } }
    @media (min-width: 992px) {
      .product-items-carousel .item {
        width: 23%;
        margin-right: 2.7%; } }

.carousel-cell {
  width: 100%; }
  .carousel-cell::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    transition: opacity .3s 1s; }
  .carousel-cell .carousel__hold {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center; }
  .carousel-cell .carousel__title, .carousel-cell .btn--simple {
    transform: translateY(-200%);
    opacity: 0;
    transition: transform .3s 1s, opacity .3s 1s, background-color .3s; }
  .carousel-cell .btn--simple {
    transform: translateY(200%); }
  .carousel-cell .carousel__title {
    font-family: big-caslon-fb, serif;
    font-size: 3rem;
    color: white;
    padding: 0 20px;
    margin-bottom: 35px;
    transform: translateY(-200%); }
    .carousel-cell .carousel__title span {
      position: relative; }
      .carousel-cell .carousel__title span::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: #F5A623;
        opacity: 0;
        width: 0;
        transition: width .3s 1.5s, opacity .1s 1.5s; }
    @media (min-width: 768px) {
      .carousel-cell .carousel__title {
        font-size: 4.5rem; } }
    @media (min-width: 1260px) {
      .carousel-cell .carousel__title {
        font-size: 6rem; } }
  .carousel-cell.is-selected .carousel__title {
    transform: translateY(0);
    opacity: 1; }
    .carousel-cell.is-selected .carousel__title span::before {
      opacity: 1;
      width: 100%; }
  .carousel-cell.is-selected .btn--simple {
    transform: translateY(0);
    opacity: 1; }
  .carousel-cell.is-selected::before {
    opacity: .5; }

.slider__hint {
  display: none; }
  @media (min-width: 768px) {
    .slider__hint {
      display: block;
      margin-top: 35px; } }
  .slider__hint p {
    display: inline-block;
    position: relative;
    font-weight: 600;
    transition: transform .3s; }
    .slider__hint p::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 1px;
      background-color: #F5A623;
      transition: width .3s; }
  .slider__hint .slider__hint--item {
    text-align: center;
    cursor: pointer; }
    .slider__hint .slider__hint--item.active p {
      transform: translateY(-15px); }
      .slider__hint .slider__hint--item.active p::before {
        width: 100%; }

.slider__next--swatch, .slider__prev--swatch {
  cursor: pointer; }

@media print {
  .no-print {
    display: none; } }
