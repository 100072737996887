/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

//Overwrite
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 30%;
  top: 20%;
  width: 40%;
  height: 60%;
}

.flickity-button {
  background-color: palette(brand, yellow);
  opacity: 0.85;
  width: 80px;
  height: 80px;
  transition: opacity .3s;

  &.previous {
    left: -10px;
  }

  &.next {
    right: -10px;
  }

  svg path {
    fill: white;
  }

  &:hover {
    background-color: palette(brand, yellow);
    opacity: 1;
  }


  @include breakpoint(small) {
    &.previous {
      left: -40px;
    }

    &.next {
      right: -40px;
    }
  }
}


.flickity-prev-next-button {
  border-radius: 0;
}

.swatch__counter {
  @include breakpoint(small) {
    padding-top: 20px;
  }
}

.swatches__item {
  width: 50%;

  img {
    padding: 0 10px;
  }

  @include breakpoint(small) {
    width: 33%;
  }
  @include breakpoint(medium) {
    width: 25%;
  }

  .swatch__title {
    font-size: 2rem;
    font-weight: 600;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px 30px;

    @include breakpoint(medium) {
      font-size: 3.2rem;
    }
  }
}

.logo-carousel {
  padding: 0 40px;

  .flickity-button {
    width: 40px;
    height: 40px;
  }

  .item {
    width: 50%;


    .tile--partner {
      display: flex;
      width: 100%;
      height: 160px;
      align-items: center;
    }

    img {
      max-height: 150px;
    }

    @include breakpoint(small) {
      width: 33.33%;
    }

    @include breakpoint(medium) {
      width: 25%;
    }

    @include breakpoint(large) {
      width: 16.66%;
    }
  }
}

.product-items-carousel {
  padding: 0 0;

  .flickity-button {
    width: 40px;
    height: 40px;
  }

  .item {
    width: 48%;
    margin-right: 4%;

    @include breakpoint(small) {
      width: 31.33%;
      margin-right: 3%;
    }

    @include breakpoint(medium) {
      width: 23%;
      margin-right: 2.7%;
    }
  }
}

.carousel-cell {
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    transition: opacity .3s 1s;
  }

  .carousel__hold {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }

  .carousel__title, .btn--simple {
    transform: translateY(-200%);
    opacity: 0;
    transition: transform .3s 1s, opacity .3s 1s, background-color .3s;
  }

  .btn--simple {
    transform: translateY(200%);
  }

  .carousel__title {
    font-family: $fancy-heading-font;
    font-size: 3rem;
    color: white;
    padding: 0 20px;
    margin-bottom: 35px;
    transform: translateY(-200%);

    span {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: palette(brand, yellow);
        opacity: 0;
        width: 0;
        transition: width .3s 1.5s, opacity .1s 1.5s;
      }
    }

    @include breakpoint(small) {
      font-size: 4.5rem;
    }

    @include breakpoint(large) {
      font-size: 6rem;
    }
  }

  &.is-selected {

    .carousel__title {
      transform: translateY(0);
      opacity: 1;

      span::before {
        opacity: 1;
        width: 100%;
      }
    }

    .btn--simple {
      transform: translateY(0);
      opacity: 1;
    }

    &::before {
      opacity: .5;
    }
  }
}

.slider__hint {
  display: none;

  @include breakpoint(small) {
    display: block;
    margin-top: 35px;
  }

  p {
    display: inline-block;
    position: relative;
    font-weight: 600;
    transition: transform .3s;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 1px;
      background-color: palette(brand, yellow);
      transition: width .3s;
    }
  }

  .slider__hint--item {
    text-align: center;
    cursor: pointer;

    &.active {


      p {
        transform: translateY(-15px);

        &::before {
          width: 100%;
        }
      }
    }
  }
}

.slider__next--swatch, .slider__prev--swatch {
  cursor: pointer;
}