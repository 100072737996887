// ==========================================================================
// #HEADER
// ==========================================================================
#header {
  background-color: #F1EEED;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .text path {
    fill: #4A4A49;
  }

  .logo {
    position: relative;
    z-index: 998;
    display: inline-block;
    padding: 15px gutter(1);
  }

  .navigation-burger__panel, .navigation__socials, .navigation__news {
    display: none;
  }

  .navigation-burger {
    padding: 20px gutter(1);
  }

  .navigation {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100svh;
    background-color: #4A4A49;
    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
    transition: opacity .2s linear, visibility .7s;

    .navigation__inner {
      margin-top: 80px;
      padding-bottom: 100px;
    }

    .ul--nav {
      list-style: none;

      &:first-of-type {
        padding: 30px 30px 0;
        display: inline-block;
      }

      &:last-of-type {
        padding: 0 30px 30px;
      }

      li {
        display: block;
        white-space: nowrap;
        margin-bottom: 35px;
      }

      a {
        position: relative;
        color: white;
        font-size: 3rem;
        line-height: 1;
        font-family: $fancy-heading-font;
        font-weight: 300;
        transition: color .3s, background-color .3s, padding .3s;
      }
    }

    .dropdown__hold {
      a {
        &.active {
          padding-right: 40px;
        }
      }
    }

    .dropdown {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.15s ease-out;
      min-height: 100%;
      margin: 0 -30px 0;
      background-color: palette(brand, yellow);

      ul {
        margin: 0;
        padding: 30px 30px 30px 60px;

        li:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        color: #4A4A4A;
        font-weight: bold;
        font-family: $body-font;
        font-size: 2.2rem;
      }

      // Drop down open
      &.open {
        transition: max-height 0.25s ease-in;
        max-height: 500px;
      }
    }
  }

  // Menu open
  &.open {

    .logo::before {
      opacity: 0 !important;
    }

    .text path {
      fill: white !important;
    }

    .navigation {
      opacity: 1;
      visibility: visible;
      transition: opacity .2s linear, visibility .7s, transform .3s;
    }
  }
}

@include breakpoint(small) {
  #header {
    background-color: transparent;

    .logo--reverse .text path {
      fill: white;
    }

    .navigation {
      @include bg-cover;
      background-image: url('/dist/img/bkg/cast-stone-menu.jpg');

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #242424;
        opacity: .8;
      }

      .navigation__inner {
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        padding-bottom: 0;

        a {
          position: relative;
          &:hover {
            color: palette(brand, yellow);
          }
        }
      }

      .ul--nav {
        padding: 30px 0 30px 30px;

        &:first-of-type {
          padding: 30px 0 0 30px;
        }

        &:last-of-type {
          padding: 0 0 30px 30px;
        }

      }

      .navigation__inner {

        .dropdown__hold {
          position: relative;

          > a:hover {
            color: white;
          }

          .dropdown {
            position: absolute;
            left: 100%;
            padding-left: 150px;
            top: 0;
            background-color: transparent;
            max-height: initial;
            margin: 0;
            width: auto;
            pointer-events: none;

            ul {
              padding: 0;
              opacity: 0;
              visibility: hidden;
              transition: opacity .3s 0s, visibility .3s 0s;
            }

            a {
              font-weight: 300;
              color: #D8D8D8;

              &::before {
                content: none;
              }

              &:hover {
                color: palette(brand, yellow);
              }
            }

            &::before, &::after {
              opacity: 0;
              transition: opacity .3s;
            }

            &::before {
              content: "";
              position: absolute;
              top: 12px;
              left: 0;
              height: 1px;
              background-color: palette(brand, yellow);
              width: 0;
              transform: none;
              transition: opacity .3s, width .3s;
            }

            &::after {
              content: "•";
              color: palette(brand, yellow);
              position: absolute;
              left: 127px;
              font-size: 39px;
              line-height: 1;
              top: 0;
              margin-left: 0;
              margin-top: -10px;
              transition: opacity .3s 0s;
            }
          }

          > a {
            display: block;
            width: 100%;
            padding-right: 15px;

            &::before {
              content: none;
            }
          }

          &:hover {

            .dropdown {
              pointer-events: all;
              z-index: 1;

              ul {
                visibility: visible;
                opacity: 1;
                transition: opacity .3s .8s, visibility .3s .8s;
              }

              &::before, &::after {
                opacity: 1;
              }

              &::before {
                width: 130px;
                opacity: 1;
              }

              &::after {
                transition: opacity .3s .4s;
              }
            }
          }
        }
      }

      .navigation__socials {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: white;
        padding: 25px;
        width: 40%;
        max-width: 600px;
        text-align: right;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 100%;
          bottom: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 90px 90px;
          border-color: transparent transparent white transparent;
        }

        li {
          margin-right: 15px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .navigation-burger__panel {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      background-color: #4A4A4A;
      clip-path: polygon(100% 0, 100% 100%, 20% 100%, 0 80%, 0 0);
      height: 65px;
      width: 65px;
      opacity: 0;
      transition: opacity .3s;
    }

    .logo {
      position: relative;

      a {
        position: relative;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        clip-path: polygon(100% 0, 100% 70%, 85% 100%, 0 100%, 0 0);
        background-color: palette(grey, light);
        opacity: 0;
        transition: opacity .3s;
      }
    }
  }

  .scrolled #header {


    .logo--reverse .text path {
      fill: #4A4A49;
    }

    .navigation-burger__panel, .logo::before {
      opacity: 1;
    }
  }
}

@include breakpoint(medium) {
  #header .navigation {
    .navigation__inner {
      left: 45%;
      z-index: 1;

      .dropdown__hold {

        .dropdown {
          padding-left: 70px;

          &::after {
            left: 50px;
          }

          &.open::before {
            width: 53px;
          }
        }

        &:hover {
          .dropdown::before {
            width: 53px;
            opacity: 1;
          }
        }

      }
    }

    .ul--nav {
      padding: 30px 0 30px 0;

      > li > a {
        font-size: 3.2rem;
      }
    }

    .navigation {
      overflow: hidden;
    }

    .navigation__news {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;
      padding: 30px 50px 30px 100px;
      color: white;

      .news_block {
        color: white;
        display: block;
        max-width: 245px;
        margin-bottom: 50px;

        .icon {
          transition: transform .3s;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        .heading {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 1.8rem;
        }

        p {
          margin-bottom: 10px;
          font-size: 1.6rem;
          font-weight: 400;
        }

        &:hover {
          .icon {
            transform: translateX(10px);

            svg path {
              fill: palette(brand, yellow);
            }
          }
        }
      }
    }
  }
}


@include breakpoint(large) {
  #header .navigation {

    .navigation__news {
      .news_block {
        max-width: 280px;
      }
    }

    .navigation__inner {
      .dropdown__hold {

        &:hover {
          .dropdown::before {
            width: 130px;
            opacity: 1;
          }
        }

        .dropdown {
          padding-left: 150px;

          &::after {
            left: 127px;
          }

          &:hover {
            &::before {
              width: 130px;
            }
          }

        }
      }
    }
  }
}
