$palettes: (
        black: (
                base: #333333
        ),
        grey: (
                light: #F1EEED,
                base: #5f5f5f,
                dark: #3a3a3a
        ),
        green: (
                success: #32b292
        ),
        red: (
                error: #EE3D52
        ),
        brand: (
                yellow: #F5A623,
                grey: #4A4A4A
        )
) !global;

// ==========================================================================
// COLOR PALETTE FUNCTION
// ==========================================================================
// Example:
//
// a {
//     color: palette(yellow, light);
// }
//

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}