//=====================================
// #CHUNK
//=====================================
.chunk {
  @include clearfix();
  display: block;
  clear: both;
  position: relative;
  overflow: hidden;

  &--ov {
    overflow: visible;
  }
}

.chunk--grey-light {
  background-color: palette(grey, light);

  .img--clip-left-top::before {
    border-color: palette(grey, light) transparent transparent transparent;
  }
}

.chunk--yellow {
  background-color: #FFF0D6;
}

.chunk--block {
  padding: 15px;

  @include breakpoint(medium) {
    padding: 50px;
  }
}

.tick {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 35px;

  &::before {
    content:"";
    position: absolute;
    left:0;
    top:0;
    background-image: url('/dist/img/icons/tick.svg');
    background-size: 28px 28px;
    height: 28px;
    width: 28px;
  }
}

.chunk--lines {
  //@include breakpoint(medium) {
  //  background-image: url('/dist/img/icons/bkg.svg');
  //  background-size: 1200px;
  //  background-position: center top;
  //  background-repeat: repeat-y;
  //}
  //
  //@include breakpoint(large) {
  //  background-size: 1600px;
  //}
}