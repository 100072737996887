//=====================================
// #HERO
//=====================================
.hero {
  position: relative;
  width: 100%;

  h1, .h1 {
    font-family: $fancy-heading-font;
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;

    strong {
      line-height: 1;
      display: inline-block;
      font-weight: 300;
      border-bottom: 1px solid palette(brand, yellow);
    }
  }

  .hero__socials {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 15px;
    max-width: 600px;
    text-align: right;

    .line {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 70px 70px;
      border-color: transparent transparent white transparent;
    }

    li {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--error {
    background-color: palette(grey, light);

    .hero__socials::after {
      content: none !important;
    }
  }

  &--full {
    min-height: 100vh !important;

    .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

.hero--slim {
  padding-top: 150px;
  background-color: palette(grey, light);

  h1 {
    display: inline-block;
    margin-bottom: 40px;
  }
}

.hero--select {
  h1 {
    margin-bottom: 20px;
  }

  .dropdown {
    margin-bottom: 40px;

    .trigger {
      overflow: hidden;
    }
  }
}

.hero--media {
  position: relative;
  padding-top: 150px;
  padding-bottom: 110px;
  @include bg-cover;
  color: white;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc000000', endColorstr='#80000000', GradientType=0);
  }

  h1 {
    display: inline-block;
    border-bottom: 1px solid palette(brand, yellow);
  }

}

.hero__success {
  border-top: 3px solid white;
  padding-top: 15px;
  background-color: #138113;

  p {
    color: white;
  }
}

.hero__bar {
  border-top: 3px solid white;
  padding-top: 10px;
  padding-bottom: 10px;

}

@include breakpoint(small) {

  .hero--select {

    .wrapper {
      display: flex;
      align-items: center;
    }

    .dropdown {
      margin-left: auto;
    }

    h1, .h1, .dropdown {
      margin-bottom: 0;
    }

    padding-bottom: 40px;
  }

  .hero--media .hero__socials {
    width: 40%;
  }
}


@include breakpoint(medium) {

  .hero {
    h1, .h1 {
      font-size: 7rem;
    }

    .hero__socials {
      padding: 25px;

      &::before {
        border-width: 0 0 90px 90px;
      }

      .line {
        display: block;
        height: 270px;
        position: absolute;
        width: 1px;
        background-color: #F5A623;
        left: -476px;
        top: -5px;
        transform: translateY(-50%) rotate(45deg);
        transform-origin: center center;
      }

      &::after {
        content: "";
        animation: .5s ease-out 0s 1 lineIn;
        height: 390px;
        position: absolute;
        width: 1px;
        background-color: #F5A623;
        left: -45px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transform-origin: center center;
      }
    }
  }

  .hero--slim {
    padding-top: 190px;
  }

  .hero--media {
    padding-top: 220px;
    padding-bottom: 180px;
  }
}


@include breakpoint(large) {

  .hero--media {
    padding-top: 260px;
    padding-bottom: 200px;


    .hero__socials {
      .line {

        left: -676px;
      }
    }
  }


}

@keyframes lineIn {
  0% {
    height: 0;
  }
  100% {
    height: 390px;
  }
}

@supports (-ms-ime-align:auto) {
  .hero--media {
    overflow: hidden;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero--media {
    overflow: hidden;
  }
}
//
//@include breakpoint(medium) {
//  .lines-middle-left {
//    position: relative;
//    &::before {
//      content: "";
//      display: block;
//      height: 270px;
//      position: absolute;
//      width: 1px;
//      background-color: #F5A623;
//      left: -136px;
//      top: 50%;
//      transform: translateY(-50%) rotate(45deg);
//      transform-origin: center center;
//    }
//  }
//  .lines-bottom-left {
//    position: relative;
//    &::before {
//      content: "";
//      display: block;
//      height: 270px;
//      position: absolute;
//      width: 1px;
//      background-color: #F5A623;
//      left: -136px;
//      bottom: 0%;
//      transform: translateY(50%)  rotate(45deg);
//      transform-origin: center center;
//    }
//  }
//  .lines-top-left {
//    position: relative;
//    &::before {
//      content: "";
//      display: block;
//      height: 270px;
//      position: absolute;
//      width: 1px;
//      background-color: #F5A623;
//      left: -136px;
//      top: 0%;
//      transform: translateY(0%)  rotate(45deg);
//      transform-origin: center center;
//    }
//  }
//}