//=====================================
// #HELPERS
//=====================================
.short {
  margin-bottom: 5px;
}


.bump {
  margin-bottom: 20px;
}

.bump--sm {
  margin-bottom: 40px;
}

.bump--md {
  margin-bottom: 60px;
}

.nudge {
  margin-right: 15px;
}

.nudge--sm {
  margin-right: 5px;
}

.nudge-left {
  margin-left: 15px;
}

@include breakpoint(medium) {
  .push-down--intro {
    padding-top: 90px;
  }
}

.spacer {
  margin-bottom: 8vh;

  &--large {
    margin-bottom: 8vh;
    @include breakpoint(small) {
      margin-bottom: 15vh;
    }
  }

  &-top {
    margin-top: 8vh;

    &--large {
      margin-top: 8vh;
      @include breakpoint(small) {
        margin-top: 15vh;
      }
    }
  }
}

.p-spacer {
  padding-bottom: 8vh;

  &--large {
    padding-bottom: 8vh;
    @include breakpoint(small) {
      padding-bottom: 15vh;
    }
  }

  &-top {
    padding-top: 8vh;

    &--large {
      padding-top: 8vh;
      @include breakpoint(small) {
        padding-top: 15vh;
      }
    }
  }
}

