//=====================================
// #HEADINGS
//=====================================
h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: $heading-font;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 25px;


  &.fancy {
    font-weight: 300;
    font-family: $fancy-heading-font;

    strong {
      font-weight: 900;
      font-family: $heading-font;
    }
  }
}

h1, .h1 {
  font-size: 6rem;
  @include breakpoint(small) {
    font-size: $h1-size;
  }
}

h2, .h2 {
  font-size: 4rem;
  @include breakpoint(small) {
    font-size: $h2-size;
  }
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

.with-line {
  font-family: $fancy-heading-font;
  font-weight: normal;
  position: relative;

  span {
    position: relative;
    background-color: white;
    padding-right: 50px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
    background-color: #F5A623;
  }
}

//=====================================
// #TEXT
//=====================================
p, .p {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.7;
  margin-bottom: 20px;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: palette(black);
  font-weight: 400;
  transition: color .3s;

  &:hover {

  }
}

.link {
  font-weight: 600;
  font-size: $p-size;
  line-height: 1.7;


  &--purple {
    color: #33003C;

    &:hover {
      color: #09000d;
    }
  }
}

.link--icon {
  span {
    margin-left: 10px;
  }
}

//=====================================
// #LISTS
//=====================================
ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }

  &.ul--row {
    li {
      display: inline-block;
    }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  color: palette(black);
  padding-bottom: 15px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

//=====================================
// #BODY
//=====================================
.body {

  h2, .h2, h3, .h3, h4, .h4 {
    margin-top: 25px;
  }

  .image, .video {
    margin: 35px 0;
  }

  .frame {
    width: 100%;
    height: 250px;
    @include breakpoint(small) {
      height: 370px;
    }
    @include breakpoint(medium) {
      height: 420px;
    }
  }
}

.news__socials {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .p {
    margin-bottom: 0;
    margin-right: 15px;
  }

  li {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.tagline__hold {
  font-size: 1.8rem;
  margin-bottom: 15px;

  .split {
    margin: 0 10px;
    color: palette(brand, yellow);

    &:first-of-type {
      margin: 0 10px 0 0;
    }
  }

  .item {
    font-weight: 600;
  }
}

.line {
  position: relative;
  display: block;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: palette(brand, yellow);
  }

  h2, .icon, p {
    position: relative;
    margin: 0;
  }

  .icon {
    transition: transform .3s;
  }

  h2, p {
    display: inline-block;
    background-color: white;
    padding-right: 50px;
    font-weight: 700;
    font-size: 2.2rem;
  }

  &.ta-c {
    h2, p {
      padding-left: 50px;
    }
  }

  h2 {
    font-family: $body-font;
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    opacity: .5;
    transition: opacity .3s;
  }

  &--link {

    &::before {
      width: calc(100% - 30px);
    }

    &:hover {
      .arrow, &::before {
        opacity: 1;
      }

      .icon {
        transform: scale(1.2);
      }
    }
  }
}

.share {
  position: relative;
  width: 100%;

  .pinit {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;

    img {
      width: 100%;
      transition: transform .3s;
    }

    &:hover img {
      transform: scale(1.2);
    }
  }
}

//=====================================
// #COLOURS/LINKS
//=====================================
.color--white {
  color: white;

  > * {
    color: white;
  }
}

a {
  font-weight: 600;

  &.thin {
    font-weight: 400;
  }
}