// ==========================================================================
// HTML & Body
// ==========================================================================
$base-line-height: 28;
$min-font: 17px;
$max-font: 21px;

html {
  height: 100%;
}

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: $body-font;
  font-style: normal;
  font-weight: 300;
  color: palette(black);
  background: white;

  @include breakpoint(large) {
    font-size: 62.5% !important;
    /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */
  }
}

body {
  position: relative;
  &.fix { overflow: hidden; }
}

//::-moz-selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}
//
//::selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}

main {
  position: relative;
  z-index: 1;
  @extend %clearfix;
}